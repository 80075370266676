/*
 * @Author: your name
 * @Date: 2021-01-12 18:32:01
 * @LastEditTime: 2021-08-17 10:36:13
 * @LastEditors: 张中玉
 * @Description: In User Settings Edit
 * @FilePath: \pt-cloud-ui\src\store\modules\global.js
 *
 */
import Layout from "@/layout";
export default [
// 开发模式登录
{
  path: "/login",
  component: function component() {
    return import("@/views/login/index.vue");
  },
  hidden: true
}, {
  path: "/callback",
  name: "callback",
  hidden: true,
  meta: {},
  children: [],
  component: function component() {
    return import('@/views/callback.vue');
  }
}, {
  path: "/",
  component: Layout,
  redirect: "/dashboard",
  hidden: true,
  children: [{
    path: "dashboard",
    name: "Dashboard",
    component: function component() {
      return import("@/views/dashboard/index");
    },
    meta: {
      title: "主页",
      icon: "icon-17"
    }
  }]
}, {
  path: "/404",
  component: function component() {
    return import("@/views/404");
  },
  hidden: true
}];
import Layout from "@/layout";
export default {
  path: '/split-activity',
  name: 'splitActivity',
  component: Layout,
  redirect: '/split-activity/store',
  meta: {
    type: 0,
    only: true,
    title: '积分活动客表',
    icon: 'el-icon-lock',
    permission: "step.by.step"
  },
  children: [{
    path: 'store',
    name: 'splitActivityStore',
    component: function component() {
      return import('@/views/dealerActivity/storeActivityList/index.vue');
    },
    meta: {
      title: '门店积分活动客表',
      type: 0,
      permission: 'step.activity.list'
    }
  }, {
    path: 'dealer',
    name: 'splitActivityDealer',
    component: function component() {
      return import('@/views/dealerActivity/activityList/index.vue');
    },
    meta: {
      title: '经销商积分活动客表',
      type: 0,
      permission: 'step.customer.activity.list'
    }
  }, {
    path: 'detail',
    component: function component() {
      return import(/* webpackChunkName: "activity-create" */'@/views/dealerActivity/createDealerActivity/index.vue');
    },
    name: 'dealerActivityDetail',
    meta: {
      title: '经销商活动创建',
      permission: 'step.customer.activity.list'
    },
    hidden: true
  }, {
    path: 'activity-create',
    component: function component() {
      return import(/* webpackChunkName: "activity-create" */'@/views/activity/activityCreate/index.vue');
    },
    name: 'activityCreate',
    meta: {
      title: '扫码奖励活动创建',
      permission: 'step.activity.list'
    },
    hidden: true
  }, {
    path: 'activity-create-display',
    component: function component() {
      return import(/* webpackChunkName: "activity-create-display" */'@/views/activity/displayActivityCreate/index.vue');
    },
    name: 'displayActivityCreate',
    meta: {
      title: '商品陈列活动创建',
      permission: 'step.activity.list'
    },
    hidden: true
  }, {
    path: 'activity-create-stores',
    component: function component() {
      return import(/* webpackChunkName: "activity-create" */'@/views/activity/storesActivityCreate/index.vue');
    },
    name: 'storesActivityCreate',
    meta: {
      title: '门店推销活动创建',
      permission: 'step.activity.list'
    },
    hidden: true
  }, {
    path: 'activity-create-clerk',
    component: function component() {
      return import(/* webpackChunkName: "activity-create" */'@/views/activity/clerkActivityCreate/index.vue');
    },
    name: 'clerkActivityCreate',
    meta: {
      title: '店员激励活动创建',
      permission: 'step.activity.list'
    },
    hidden: true
  }, {
    path: 'activity-trade-in',
    component: function component() {
      return import(/* webpackChunkName: "activity-create" */'@/views/activity/trade-in/index.vue');
    },
    name: 'tradeInActivityCreate',
    meta: {
      title: '一元换购活动创建',
      permission: 'step.activity.list'
    },
    hidden: true
  }]
};